import React from 'react';
import styled from 'styled-components';
import Profiles from 'RootCommon/teams';
import Section from './__Section';
import Title from './__Title';
// 上线新闻
const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 2.4rem 3rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    margin-top: 4.9rem;
    .profile {
      display: flex;
      flex-direction: column;
      width: 15.9rem;
      height: 18.2rem;
      margin-bottom: 1.4rem;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0px -9px 14px 8px rgba(56, 0, 95, 0.08);
      transition: all 0.8s;
      .avator {
        width: 100%;
        height: 12.1rem;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .intro {
        padding: 0.8rem 0.6rem 1.4rem 1.2rem;
        .name {
          font-size: 1.4rem;
          color: #800586;
          font-weight: 800;
        }
        .titles {
          font-size: 0.8rem;
          margin-top: 0.4rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 1.6;
          > span {
            white-space: nowrap;
            color: #636363;
          }
        }
      }
    }
  }
`;

const Team = () => {
  return (
    <Section id="team">
      <Wrapper>
        <Title text='"紫荆云"专家顾问' />

        <div className="content">
          {Profiles.map((p) => (
            <div key={p.name} className="profile">
              <div className="avator" style={{ backgroundColor: p.bgColor || 'none' }}>
                <img src={p.avator} alt="个人头像" />
              </div>
              <div className="intro">
                <h2 className="name">{p.name}</h2>
                <p className="titles">
                  {p.titles.map((t) => (
                    <span key={t}>{t}</span>
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Team;
