import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
import AsideImage from 'imgs/zj/aside.box.png';

// 2021-06-25更新官网新闻
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tip {
    width: 32.7rem;
    padding: 2.7rem 0 0 0;
    margin-bottom: 5.9rem;
    color: #424242;
    font-size: 1.1rem;
    line-height: 2;
    text-align: left;
  }
  .box {
    width: 35.9rem;
    height:20rem;
    position: relative;
     .boxImg{
      position: absolute;
      width: 100%;
      z-index: 99;
      >img{
        width:100%;

      }
    }
    .circle {
      z-index: 1;
      position: absolute;
      top: -4rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 25.2rem;
      height: 25.2rem;
      background-color: #ecdaee;
      border-radius: 50%;
    }
  }
`;

const AIBox = () => {
  return (
    <Section id={'ai_box'}>
      <Wrapper>
        <Title text={'"紫荆云"智能教学系统'} />
        <div className="tip">
        “紫荆云”，依托强大的技术实力，搭建儿童素质教育智能教学系统，课程锁定云端，现场通过遥控器控制机顶盒，把优质的素质教育内容带到各个教育机构、带给每个孩子。
        </div>
        <div className="box">
          <div className="circle">&nbsp;</div>
          <div className='boxImg'>
           <img src={AsideImage} alt="智能盒子配图" />
          </div>

        </div>
      </Wrapper>
    </Section>
  );
};

export default AIBox;
