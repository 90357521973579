import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.header`
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
  position: relative;
  color: #75007c;
  font-weight: 800;
  margin-top: 5rem;
  align-self: center;
  .widget {
    position: absolute;
    top: 0;
    width: 1rem;
    height: 1rem;
    background: #a575b9;
    &:after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      background: #75007c;
      position: absolute;
      top: 50%;
    }
    &.left {
      left: -3rem;
      &:after {
        right: 50%;
      }
    }
    &.right {
      right: -2.4rem;
      &:after {
        left: 50%;
      }
    }
  }
`;
export default function Title({ text = '默认标题' }) {
  return (
    <StyledWrapper>
      <div className="widget left" />
      {text}
      <div className="widget right" />
    </StyledWrapper>
  );
}
