import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
import ArrowUp from './__ArrowUp';

import News from 'RootCommon/news';

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 2.4rem 3rem 2.5rem;
  display: flex;
  flex-direction: column;
  .items {
    margin-top: 3rem;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 1.6rem;
      margin-bottom: 1.8rem;
      border-radius: 1.3rem;
      box-shadow: 0px -9px 18px 8px rgba(56, 0, 95, 0.05);
      .img {
        width: 6.8rem;
        height: 6.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #eaeaea;
        .icon {
          width: 54%;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 20rem;
        > h2 {
          font-size: 1.2rem;
          color: #424242;
          line-height: 2.4;
          font-weight: 800;
        }
        .desc {
          text-align: justify;
          font-size: 1rem;
          line-height: 1.6;
          color: #595959;
          p {
            color: #595959;
          }
          p:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`;

const Strategy = () => {
  return (
    <Section id="strategy">
      <ArrowUp width="3.8rem" pos={{ right: '9.69%', top: '3rem' }} />
      <Wrapper>
        <Title text={'"紫荆云"三大亮点'} />
        <ul className="items">
          {News.map((d) => {
            let { title, icon, desc } = d;
            return (
              <li key={title} className="item">
                <div className="img">
                  <img className="icon" src={icon} />
                </div>
                <div className="content">
                  <h2>{title}</h2>
                  <div className="desc">
                    {Array.isArray(desc) ? desc.map((d) => <p key={d}>{d}</p>) : desc}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </Wrapper>
    </Section>
  );
};

export default Strategy;
