import React from 'react';
import styled from 'styled-components';
import Courses from 'RootCommon/course';
import Section from './__Section';
import ArrowUp from './__ArrowUp';
import Title from './__Title';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 2.4rem 2.3rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 32rem;
    margin-top: 3.4rem;
    .course {
      width: 15.5rem;
      margin-bottom: 2rem;
      .cover {
        width: 100%;
        border-radius: 1rem;
        margin-bottom: 0.8rem;
        transition: all 0.5s;
      }
      > h2 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0.8rem;
        color: #424242;
      }
      > h3 {
        font-size: 1.1rem;
        margin-bottom: 0.9rem;
        color: #787878;
      }
      ul {
        li {
          white-space: nowrap;
          overflow: hidden;
          font-size: 0.75rem;
          padding-left: 0.6rem;
          line-height: 2.6;
          position: relative;
          color: #424242;
          a {
            text-decoration: none;
          }
          &:before {
            position: absolute;
            left: 0;
            top: 0.5rem;
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 0.4rem solid transparent;
            border-bottom: 0.4rem solid transparent;
            border-left: 0.4rem solid #edbc04;
          }
        }
      }
    }
  }
`;

const Course = () => {
  function createMarkup(item) {
    return { __html: item };
  }

  return (
    <Section id="course">
      <ArrowUp width="24.5rem" pos={{ right: '2.24%', bottom: '45%' }} />
      <ArrowUp width="10.5rem" pos={{ left: '3.18%', bottom: '5%' }} />
      <ArrowUp type={4} width="6.4rem" pos={{ left: '11.2%', top: '11.5rem' }} />

      <Wrapper>
        <Title text={'“紫荆云”优质素质教育课程'} />
        <div className="content">
          {Courses.map((c) => {
            const { title, desc, items, cover } = c;
            return (
              <div key={title} className="course">
                <img className="cover" src={cover} alt="课程封面" />
                <h2>{title}</h2>
                <h3>{desc}</h3>
                <ul>
                  {items.map((item) => {
                    return <li key={item} dangerouslySetInnerHTML={createMarkup(item)}></li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Course;
