import React from 'react';
import styled from 'styled-components';
import GlobalStyle from './__Global.style';
// import Contact from 'RootCommon/MContact';
import MTA from 'RootCommon/MTA';

// import Loading from './_Loading';
import Banner from './_Banner';
import Intro from './_Intro';
// import Growth from './_Growth';
// import Opportunity from './_Opportunity';
import Strategy from './_Strategy';
import Course from './_Course';
import AIBox from './_AIBox';
// import Teachers from './_Teachers';
// import Operation from './_Operation';
import Team from './_Team';
// import Invite from './_Invite';
import Footer from './_Footer';
import Cooperation from './_Cooperation';
import Contact from 'RootCommon/MContact';

const Main = styled.main`
  max-width: 40rem;
  background: #f4f3f4;
  margin: 0 auto;
`;
const MIndex = () => {
  return (
    <Main>
      <GlobalStyle />
      <Banner />
      {/* 什么是紫荆云 */}
      <Intro />
      {/* 创新增长 */}
      {/* <Growth /> */}
      {/* 市场机会 */}
      {/* <Opportunity /> */}
      {/* 三大策略 */}
      <Strategy />
      {/* 十大课程 */}
      <Course />
      {/* 智能教学系统 */}
      <AIBox />
      {/* 师资团队 */}
      {/* <Teachers /> */}
      {/* 运营系统 */}
      {/* <Operation /> */}
      {/* 专家顾问团队 */}
      <Team />
      {/* 诚邀加入 */}
      {/* <Invite /> */}
      {/* 战略合作 */}
      <Cooperation />
      <Footer />
      {process.env.NODE_ENV === 'production' && <MTA sid="500690948" />}
      <Contact />
    </Main>
  );
};

export default MIndex;
