import React from 'react';
import styled from 'styled-components';

import Section from './__Section';
import Title from './__Title';

import AsideImage from 'imgs/zj/aside.intro.mobile.png';
import DotImage from 'imgs/zj/dot.png';
const StyledSection = styled(Section)`
  overflow: visible;
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    padding: 3.8rem 0 0 0;
    .aside {
      width: 100%;
      margin-bottom: -3rem;
    }
    .intro {
      position: relative;
      margin: 4rem 2.4rem 1rem 2.5rem;
      .dot {
        position: absolute;
        width: 3.9rem;
        &.top {
          left: 0;
          top: -4.4rem;
        }
        &.btm {
          right: 0;
          bottom: -1.5rem;
          transform: rotate(180deg);
        }
      }
      > p {
        text-align: justify;
        line-height: 2.5rem;
        font-size: 1.1rem;
        margin-bottom: 1.2rem;
      }
    }
  }
`;

const Intro = () => {
  return (
    <StyledSection id="intro">
      <Wrapper>
        <Title text={'什么是"紫荆云"？'} />
        <div className="content">
          <div className="intro">
            <img src={DotImage} alt="上面逗号" className="dot top" />
            <img src={DotImage} alt="下面逗号" className="dot btm" />
            <p>“紫荆云”，全称“紫荆儿童成长云平台”，是专门服务 3-10 岁儿童的素质教育互联网平台。</p>
            <p>
            “紫荆云”，专注研究儿童素质教育，让全国各地的孩子都能享受和北京孩子同步的素质教育是我们的初心。我们始终坚持以孩子为中心，以素质教育为本，专注儿童成长，追求卓越品质。
            </p>
          </div>
          <img className="aside" src={AsideImage} alt="紫荆工厂配图" />
        </div>
      </Wrapper>
    </StyledSection>
  );
};

export default Intro;
