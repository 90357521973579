import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import ArrowUp from './__ArrowUp';
import LogoImg from 'imgs/zj/logo.top.png';
import AsideImage from 'imgs/zj/aside.banner.girl.png';
const StyledSection = styled(Section)`
  margin-bottom: 0;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 8rem 0 4rem 2.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .logo {
    position: absolute;
    top: 2.6rem;
    right: 2.9rem;
    width: 8rem;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    .head {
      font-size: 3rem;
      font-weight: 800;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      > span {
        line-height: 4rem;
        color: #fff;
      }
    }
    .desc {
      background: #e5a537;
      border-radius: 0.5rem;
      color: #9205ab;
      font-size: 1.4rem;
      line-height: 1.4;
      padding: 0.3rem 1.2rem;
    }
    .list {
      padding: 1rem 0;
      margin-top: 1rem;
      list-style-type: none;
      > li {
        line-height: 1.8;
        font-size: 1.15rem;
        color: #fff;
        position: relative;
        padding-left: 1rem;
        font-weight: bolder;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0.7rem;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background-color: #e5a537;
        }
      }
      .title_li{
        padding-left: 0;
        font-weight:800;
        color:rgb(229, 165, 55);
        &:before{
          display:none;
        }  
      }
    }
  }
  .aside {
    z-index: 2;
    width: 14rem;
    align-self: flex-end;
    margin-top: -9.4rem;
  }
`;

const Banner = () => {
  return (
    <StyledSection id={'banner'} bgc={'#810187'}>
      <ArrowUp type={1} width="13.8rem" pos={{ top: '-2.6rem', left: '6.7rem' }} />
      <ArrowUp type={1} width="9.2rem" pos={{ top: '7.5rem', right: '3.9rem' }} />
      <ArrowUp type={1} width="16rem" pos={{ bottom: '1rem', left: '-6rem' }} />

      <ArrowUp type={2} width="33.6rem" pos={{ right: '-12rem', bottom: '-1rem' }} />
      <Wrapper>
        {/* <img className="arrows" src={GroupImage} alt="箭头组合" /> */}
        <img src={LogoImg} alt="紫荆儿童成长云平台" className="logo" />
        <div className="header">
          <h1 className="head">
            <span>紫荆云</span> <span>专注研究儿童素质教育</span>
          </h1>
          <h2 className="desc">个性发展 扬长补短</h2>
          <ul className="list">
            <li className='title_li'>“紫荆云”，矢志让全国各地的孩子 <br />都能享受跟北京孩子同步的素质教育</li>
            <li>如果你缺优质的素质教育课程，请找“紫荆云”</li>
            <li>
            如果你的老师需要专业落地培训，请找“紫荆云”
            </li>
            <li>
            如果你需要有人手把手带你运营素质教育项目，请找“紫荆云”
            </li>
          </ul>
        </div>
        {/* <img className="aside" src={AsideImage} alt="Banner配图" /> */}
      </Wrapper>
    </StyledSection>
  );
};

export default Banner;
