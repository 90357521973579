import styled from 'styled-components';

const Section = styled.section.attrs(() => ({
  className: 'block'
}))`
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* overflow-x:hidden;
  overflow-y:visible; */
  min-height: 20rem;
  background-color: ${({ bgc }) => (bgc ? bgc : '#fff')};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1.2rem;
`;

export default Section;
